<template>
  <div id="app">
    <Header />
    <HeaderBanner />
    <Services />
    <Features />
    <About />
    <Process />
    <Contact />
    <router-view />
    <Footer />
  </div>
</template>

<script>
export default {
  components:{
    'Header': () => import('./components/Header'),
    'HeaderBanner': () => import('./components/HeaderBanner'),
    'Services': () => import('./components/Services'),
    'Features': () => import('./components/Features'),
    'About': () => import('./components/About'),
    'Process': () => import('./components/Process'),
    'Contact': () => import('./components/Contact'),
    'Footer': () => import('./components/Footer'),
  }
}
</script>
